<template>
	<v-dialog
		:value="dialog"
		max-width="500"
		persistent scrollable
	>
		<v-card>
			<v-card-actions class="grey lighten-4 justify-space-between py-4">
				<div class="px22">
					{{ formTitle }}
				</div>
				<v-btn icon @click="close()"><v-icon>mdi-close</v-icon></v-btn>
			</v-card-actions>

			<v-card-text class="py-6"
				style="height: 70vh;"
			>
				<v-container fluid>
					<v-row class="ma-0 pa-0">
						<v-col
							cols="12"
						>
							<text-field
								v-model="editedItem.mobile"
								label="Mobile no. (*)"
								name="mobile"
								icon="mdi-phone"
								type="number"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-field
								v-model="editedItem.email"
								label="Email (*)"
								name="email"
								icon="mdi-at"
								:errors="formErrors"
								type="email"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-field
								v-model="editedItem.first_name"
								label="First name"
								name="first_name"
								icon="mdi-subtitles"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
						>
							<text-field
								v-model="editedItem.last_name"
								label="Last name"
								name="last_name"
								icon="mdi-subtitles-outline"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							v-if="detail"
							cols="12"
						>
							<text-field
								v-model="editedItem.username"
								label="Username"
								name="username"
								icon="mdi-account"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
							v-if="detail"
						>
							<check-box>
								<v-checkbox
									v-model="editedItem.push_notification"
									label="Push Notification"
									name="push_notification"
									append-icon="mdi-bell"
								/>
							</check-box>
						</v-col>
						<v-col
							cols="12"
							v-if="detail"
						>
							<select-field
								v-model="editedItem.gender"
								label="Gender"
								:items="['Male', 'Female', 'Others']"
								name="gender"
								icon="mdi-human-non-binary"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
							v-if="detail"
						>
							<date-picker
								v-model="editedItem.dob"
								label="Date of birth"
								name="dob"
								icon="mdi-cake-variant"
								:errors="formErrors"
							/>
						</v-col>
						<v-col
							cols="12"
							v-if="detail"
						>
							<select-field
								v-model="editedItem.nationality"
								label="Nationality"
								name="nationality"
								icon="mdi-hand-coin"
								:items="['Nepal', 'India']"
								:errors="formErrors"
							/>
						</v-col>
						<v-fab-transition>
							<v-col cols="12" class="d-flex align-center justify-center"
								v-if="editedItem.profile_picture"
							>
								<v-avatar
									size="120"
									color="primary"
								>
									<v-img :src="getObjectUrl"/>
								</v-avatar>
							</v-col>
						</v-fab-transition>
						<v-col
							cols="12"
							v-if="detail"
						>
							<file-field
								v-model="editedItem.profile_picture"
								label="Profile Picture"
								name="profile_picture"
								icon="mdi-image"
								accept="image/*"
								:errors="formErrors"
							/>
						</v-col>
					</v-row>
				</v-container>
			</v-card-text>

			<v-card-actions class="grey lighten-4">
				<v-spacer />
				<v-btn
					color="error darken-1"
					text rounded
					@click="close"
				>
					Cancel
				</v-btn>
				<v-btn
					:loading="saving"
					color="blue darken-1"
					text rounded
					@click="save"
				>
					Save
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import TableDialogMixin from "@/mixins/TableDialogMixin.js";

export default {
	name: "StaffCreateUpdateDialog",
	mixins: [TableDialogMixin],
	data: () => ({
		model: "staff",
		datesToFormat: [],
		requiredFields: ["mobile", "email"],
		editedItem: {
			username: "",
			first_name: "",
			last_name: "",
			email: "",
			mobile: null,
			gender: "",
			profile_picture: null,
			push_notification: false,
			dob: "",
			nationality: "",
		}
	}),
	computed: {
		...mapGetters({
			detail: "staff/inEdit",
			formErrors: "staff/formErrors"
		}),
		formTitle() {
			if (this.detail) return "Edit Staff"
			else return "New Staff"
		},
		getObjectUrl() {
			if (this.editedItem.profile_picture && typeof this.editedItem.profile_picture === "object")
				return URL.createObjectURL(this.editedItem.profile_picture)
			return ""
		},
		getEditFormData() {
			return {
				mobile: this.editedItem.mobile,
				email: this.editedItem.email,
				first_name: this.editedItem.first_name,
				last_name: this.editedItem.last_name,
				dob: this.editedItem.dob,
				push_notification: this.editedItem.push_notification,
				profile_picture: this.profile_picture,
				nationality: this.nationality,
				gender: this.editedItem.gender,
				username: this.editedItem.username
			}
		},
		getCreateFormData() {
			return {
				mobile: this.editedItem.mobile,
				email: this.editedItem.email,
				first_name: this.editedItem.first_name,
				last_name: this.editedItem.last_name
			}
		}
	},
	methods: {
		...mapMutations("staff", ["SET_FOR_EDIT", "SET_DIALOG_STATE", "SET_FORM_ERRORS"])
	}
}
</script>

<style scoped lang="scss">
@import "src/styles/fonts.scss";
</style>
